'use client';

import type { ThreadId } from '@/app/lib/entities/Thread.entity';
import { redirect } from 'next/navigation';
import { useEffect } from 'react';

export default function Page({ params: { threadId } }: { params: { threadId: ThreadId } }) {
	useEffect(() => {
		redirect(`/${threadId}/chat`);
	}, [threadId]);
}
